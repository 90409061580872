import React from 'react';

function Layout({children, lang}) {
    return (
        <>
            { children }
        </>
    );
}

export default Layout;