import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core/dist/umd/popper.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-modal-video/scss/modal-video.scss";
import './src/assets/css/plugins.css';
import './src/assets/scss/style.scss';
import Layout from './src/components/layouts/Layout';

export function wrapPageElement({element, props}) {
    return (
        <Layout { ...props }>
            { element }
        </Layout>
    );
};

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const onClientEntry = () => {
    if (!(`IntersectionObserver` in window)) {
        import(`intersection-observer`);
    }
};